const Flowbite = (callback) => {
    const existingScript = document.getElementById('flowbiteScript');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://unpkg.com/@themesberg/flowbite@latest/dist/flowbite.bundle.js';
      script.id = 'flowbiteScript';
      document.body.appendChild(script);
      script.onload = () => { 
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  };
export default Flowbite;