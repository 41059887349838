import React, { Component } from 'react';
import Intro from './Intro';


export default class Instructions extends Component {
    render() {
        return(
          <Intro/>
        )
    }
}