import React, { Component } from 'react';
import logo_light from '../assets/images/BO_Icon_lines_light_blue.png';
import logo_dark from '../assets/images/BO_Icon_lines_white.png';


export default class Intro extends Component {

    render(){
        return(
            <header className="App-header bg-transparent px-2 sm:px-4 py-2.5">
                <nav className="">
                    <div className="container flex flex-col flex-wrap justify-between items-center mx-auto">
                        <a className="flex flex-col justify-center items-center mx-auto">
                            <div className="dark:hidden"><img src={logo_light} alt="Brenton Oliver" width="50" height="50"/></div>
                            <div className="hidden dark:block"><img src={logo_dark} alt="Brenton Oliver" width="50" height="50"/></div>
                            <h1 className="px-2 self-center text-lg font-semibold whitespace-nowrap text-3xl text-blue-500 dark:text-white">Brenton Oliver</h1>
                        </a>
                        <h2 className="text-xl px-1 mx-auto text-blue-500 dark:text-white">Full Stack Developer</h2>
                    </div>
                </nav>
            </header>
        )
    }
}