import { useEffect, useState } from "react";

export default function useDarkMode() {
    
    // const [theme, setTheme] = useState('light');
    const [theme, setTheme] = useState(getCookie('themeMode') ? getCookie('themeMode') : 'light');
    const colorTheme = theme === 'light' ? 'dark' : 'light';
    useEffect(() => {
        createCookie('themeMode', theme, 7);
        const root = window.document.documentElement;
        root.classList.remove(colorTheme);
        root.classList.add(theme);
    }, [theme, colorTheme])
    return [colorTheme, setTheme];
}

function createCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function getCookie(name) {
    var cname = name + "=";
    var dc = document.cookie;
    if (dc.indexOf(cname) != -1) {
        var cstart = dc.indexOf(cname) + cname.length;
        var cend = dc.indexOf(";", cstart);
        if (cend == -1) {
        cend = dc.length;
        }
        return dc.substring(cstart, cend);
    }
    return null;
}